import * as Headless from "@headlessui/react";
import { Link as RemixLink, LinkProps } from "@remix-run/react";
import React, { forwardRef } from "react";

export const Link = forwardRef(function Link(
  props: LinkProps & React.ComponentPropsWithoutRef<"a">,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Headless.DataInteractive>
      <RemixLink {...props} ref={ref} />
    </Headless.DataInteractive>
  );
});
